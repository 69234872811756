import { Injectable } from '@angular/core';
import { UserStore } from '@trato-logistica/trato-auth';
import dayjs from 'dayjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {
  getBaseParamsFunctionResponseInterface,
  getBaseParamsWithGroupFunctionResponseInterface,
  getTimingParamsFunctionResponseInterface,
} from 'sharedInterfaces/base-ga.interface';
import { Timer } from '../services/timer/timer';

@Injectable({
  providedIn: 'root',
})
export class BaseGAService {
  constructor(
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected timer: Timer,
    protected userStore: UserStore,
  ) {}

  getTimingFromSessionStart(): number {
    const authTime = this.userStore.getTokenParsed().auth_time * 1000;
    return dayjs().valueOf() - authTime;
  }

  startTimer() {
    this.timer.startingTime();
  }

  getBaseParams(category: string): getBaseParamsFunctionResponseInterface {
    return {
      event_category: category,
      user_id: this.userStore.get().id,
    };
  }

  getTimingParams(category: string): getTimingParamsFunctionResponseInterface {
    return {
      ...this.getBaseParams(category),
      timing_from_session_start: this.getTimingFromSessionStart(),
      timing_from_screen_start: this.timer.totalTimeInMiliseconds,
    };
  }

  getTimingParamsWithGroup(category: string): getTimingParamsFunctionResponseInterface {
    return {
      ...this.getBaseParamsWithGroup(category),
      timing_from_session_start: this.getTimingFromSessionStart(),
      timing_from_screen_start: this.timer.totalTimeInMiliseconds,
    };
  }

  event(action: string, params: any): void {
    this.googleAnalyticsService.gtag('event', action, params);
  }

  handleEvent(category: string, feature: string, event: string, params?: any): void {
    this.event(`${feature}_${event}`, { ...this.getTimingParams(category), ...params });
  }

  getBaseParamsWithGroup(category: string): getBaseParamsWithGroupFunctionResponseInterface {
    return {
      event_category: category,
      user_id: this.userStore.get().id,
      user_group: this.userStore.get().group,
    };
  }
}
