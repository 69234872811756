export const softwareVersioning: string = '3.122.0';

export function apiSetting(PATH: string) {
  const PATH_CARGO_FLEX = `${PATH}/trato-cargo-flex`;
  const PATH_INTEGRA_RODO = `${PATH}/integra-rodo-entidades`;
  const PATH_DEMAND_PLAN = `${PATH}/trato-demand-plan`;
  const PATH_SECURITY = `${PATH}/trato-security`;
  const PATH_TERMINAL_QUEUE = `${PATH}/trato-terminal-queue`;
  const PATH_TRATO_RESERVATION = `${PATH}/trato-reservation`;
  const PATH_EDUCA_TRATO = `${PATH}/educa-trato`;
  const PATH_REPORTS = `${PATH}/reports`;
  const PATH_METRICS = `${PATH}/trato-metrics`;
  const PATH_POSTAL_CODE = `${PATH}/postal-code`;
  const PATH_PREMIUM_CADENCE = `${PATH}/trato-premium-cadence`;
  const PATH_IBGE = `${PATH}/ibge`;
  const PATH_FREIGHT_MATCH = `${PATH}/freight-match`;
  const PATH_TRUCK_DRIVER = `${PATH}/truck-driver/v1`;
  const PATH_AGGREGATOR_FREIGHTS = `${PATH}/freight-aggregator`;
  const PATH_SHIPPING_COMPANY = `${PATH}/shipping-company/v1`;
  const PATH_GEOGRAPHIC_COORDINATES = `${PATH}/shipping-company`;
  const PATH_SIOP_INTEGRATION = `${PATH}/trato-siop-integration-server`;
  const PATH_STOPPING_POINT = `${PATH}/entreposto`;
  const PATH_SUPERVISOR = `${PATH}/supervisor`;

  return {
    PATH_CARGO_FLEX,
    PATH_DEMAND_PLAN,
    PATH_INTEGRA_RODO,
    PATH_SECURITY,

    ACTIVE_TERMINAL: `${PATH_TERMINAL_QUEUE}/v1/scheduled-check-in/status`,
    ADVANCE_AUTONOMOUS: `${PATH_TERMINAL_QUEUE}/v1/advance-autonomous`,
    AUTH: `${PATH_SECURITY}/v1/security/authenticate`,
    BATCH: `${PATH_DEMAND_PLAN}/v1`,
    BATCH_CURRENT: `${PATH_DEMAND_PLAN}/v1/batch/current`,
    BATCH_DEMANDS: `${PATH_DEMAND_PLAN}/v1/batch`,
    BATCH_FREIGHT_TRUCK_HISTORIC: `${PATH_DEMAND_PLAN}/v1/batch/freight-truck-historic`,
    BATCH_REGISTER: `${PATH_DEMAND_PLAN}/v2/batch`,
    BATCH_VOLUMES: `${PATH_DEMAND_PLAN}/v1/batch-volumes`,
    BATCH_VOLUMES_MONTH: `${PATH_DEMAND_PLAN}/v1/batch-volumes/month`,
    BATCH_VOLUME_SPLITS: `${PATH_DEMAND_PLAN}/v1/batch-volumes/splits`,
    BENEFITS: `${PATH_INTEGRA_RODO}/v1/benefits`,
    BRAZIL_STATES: `${PATH_INTEGRA_RODO}/v1/states`,
    BUSINESS_GROUPS: `${PATH_INTEGRA_RODO}/v1/signed/user/businessGroups`,
    BUSINESS_GROUPS_v2: `${PATH_INTEGRA_RODO}/v2/businessgroup`,
    CALCULATE_FREIGHT: `${PATH_PREMIUM_CADENCE}/v1/advance-check-in/calculate-freight`,
    CALCULATE_LIST_TERMINAL: `${PATH_PREMIUM_CADENCE}/v1/terminal-param/freight/summary `,
    CARRIER_V2: `${PATH_INTEGRA_RODO}/v2/shippingcompany`,
    CHANGE_SCHEDULE_STATUS: `${PATH_INTEGRA_RODO}/v1/signed/user/loads-refused`,
    CHANGE_STATUS_USER: `${PATH}/supervisor/v1/signed/user/users`,
    CHECKING_TRAVELS: `${PATH_TERMINAL_QUEUE}/v1/travels/checkin`,
    CLIENT_TERMINAL: `${PATH_TERMINAL_QUEUE}/v1/client-terminal`,
    COMPANY: `${PATH_SHIPPING_COMPANY}/company`,
    COMPANY_CNPJ_SEARCH: `${PATH_SHIPPING_COMPANY}/search_cnpj`,
    CTE_ANALYSIS: `${PATH_INTEGRA_RODO}/v1/cte/exist`,
    CURRENT_TRAVEL_LIST: `${PATH_DEMAND_PLAN}/v1/shipping-order/current`,
    DASHBOARD_ADHERENCE: `${PATH_INTEGRA_RODO}/v1/dashboard/adherence-panel`,
    DASHBOARD_BATCH: `${PATH_DEMAND_PLAN}/v1/batch/dashboard`,
    DASHBOARD_BATCH_DETAILS: `${PATH_DEMAND_PLAN}/v1/batch/dashboard/details`,
    DASHBOARD_BATCH_VOLUME: `${PATH_DEMAND_PLAN}/v1/batch-volumes/dashboard`,
    DASHBOARD_QUOTAS: `${PATH_INTEGRA_RODO}/v2/dashboard/quotas`,
    DEMANDS_QUOTA: `${PATH_TRATO_RESERVATION}/v1/quota-demands`,
    DEMANDS_QUOTA_AGROUP: `${PATH_INTEGRA_RODO}/v1/quota-demands/agroup`,
    DEMANDS_QUOTA_QUOTAS: `${PATH_TRATO_RESERVATION}/v1/quota-demands/quotas`,
    DEMANDS_QUOTA_SUMMARY: `${PATH_INTEGRA_RODO}/v1/quota-demands/summary`,
    DEMANDS_QUOTA_TERMINALS: `${PATH_TRATO_RESERVATION}/v1/terminals`,
    DEMAND_VOLUMES_SPLITS: `${PATH_DEMAND_PLAN}/v2/batch-volumes/splits`,
    DISPATCHERS: `${PATH_INTEGRA_RODO}/v1/dispatchers`,
    DOWNLOAD_ORDER_OUT_FLOW: `${PATH_DEMAND_PLAN}/v1/shipping-order`,
    DOWNLOAD_TICKET: `${PATH_INTEGRA_RODO}/v1/signed/user/loads-refused/ticket`,
    EDUCATE: `${PATH_EDUCA_TRATO}/v1/categories`,
    EDUCATE_IMAGE: `${PATH_EDUCA_TRATO}`,
    FREIGHT_EXPIRATION_DAYS_INFO: `${PATH_AGGREGATOR_FREIGHTS}/v1/schedule/properties`,
    FREIGHT_MATCH_TRUCK_DRIVER: `${PATH_FREIGHT_MATCH}/v1/truck-driver`,
    GATEWAY_PATH: PATH,
    GEOGRAPHIC_COORDINATES: `${PATH_GEOGRAPHIC_COORDINATES}/search_location`,
    GROUPS: `${PATH_INTEGRA_RODO}/v1/groups`,
    GROUP_HIERARCHY: `${PATH_INTEGRA_RODO}/v1/signed/user/groups`,
    HISTORY_TAC: `${PATH_TERMINAL_QUEUE}/v1/payments`,
    IBGE: `${PATH_IBGE}/v1`,
    INSPECTION_QUIZ: `${PATH_INTEGRA_RODO}/v1/signed/truck-driver/quiz/vehicle-inspection`,
    INTEGRA_QUOTAS: `${PATH_INTEGRA_RODO}/v1/quotas`,
    INTEGRA_TRAVELS: `${PATH_INTEGRA_RODO}/v3/travels`,
    INVOCES: `${PATH_INTEGRA_RODO}/v1/invoices`,
    INVOCE_ANALYSIS: `${PATH_INTEGRA_RODO}/v1/invoice/analysis`,
    INVOICES_V2: `${PATH_INTEGRA_RODO}/v2/invoices`,
    INVOICE_VALIDATE: `${PATH_INTEGRA_RODO}/v1/invoice/validate`,
    IS_VLI: `${PATH_INTEGRA_RODO}/v1/signed/user/is-vli-user`,
    LIST_FREIGHT_AGGREGATOR: `${PATH_AGGREGATOR_FREIGHTS}/v1/freights`,
    LIST_TERMINAL: `${PATH_TERMINAL_QUEUE}/v1/client-terminal/by-terminal-client`,
    MESSAGES: `${PATH_INTEGRA_RODO}/v1/messages`,
    METRICS_TERMINALS: `${PATH_METRICS}/v1/terminals`,
    NEED_ANSWER: `${PATH_PREMIUM_CADENCE}/v1/survey/needAnswer`,
    OCCURENCES: `${PATH_CARGO_FLEX}/v1/occurences`,
    OCCURRENCE_RECORD: `${PATH_INTEGRA_RODO}/v1/schedules/occurrence-record`,
    ORIGINS: `${PATH_INTEGRA_RODO}/v1/origins`,
    OVERTAKING_CHECK_IN: `${PATH_TERMINAL_QUEUE}/v1/scheduled-check-in`,
    OVERTAKING_CLIENT_PRODUCTIVITY_PLATE: `${PATH_TERMINAL_QUEUE}/v1/travels/get-client-productivity-by-plate`,
    OVERTAKING_DAYS: `${PATH_TERMINAL_QUEUE}/v1/scheduled-check-in/available-days`,
    OVERTAKING_HOURS: `${PATH_TERMINAL_QUEUE}/v1/scheduled-check-in/available-hours`,
    PAGBEM: `${PATH_TRUCK_DRIVER}/pag-bem`,
    PATH_CLIENT_PARAMS: `${PATH_TERMINAL_QUEUE}/v1/client-terminal`,
    PATH_TERMINAL_PARAMS: `${PATH_TERMINAL_QUEUE}/v1/terminal-queue/terminals`,
    POSTAL_CODE: `${PATH_POSTAL_CODE}/v1`,
    PRODUCTIVITY_TRAVELS: `${PATH_TERMINAL_QUEUE}/v1/travels`,
    PRODUCTS: `${PATH_INTEGRA_RODO}/v2/products`,
    PRODUCTS_NCM: `${PATH_INTEGRA_RODO}/v1/products/ncm`,
    PRODUCTS_V1: `${PATH_INTEGRA_RODO}/v1/products`,
    PRODUTIVITY_REPORT: `${PATH_PREMIUM_CADENCE}/v1/productivity-report`,
    PROFILES: `${PATH_INTEGRA_RODO}/v1/profiles`,
    PUSH_NOTIFICATION: `${PATH_INTEGRA_RODO}/v1/push-notification`,
    PUSH_NOTIFICATIONS: `${PATH_INTEGRA_RODO}/v1/push-notifications`,
    PUSH_NOTIFICATION_TYPE: `${PATH_INTEGRA_RODO}/v1/push-notification-types`,
    QUALITY: `${PATH_CARGO_FLEX}/v1/dashboards/quality`,
    QUESTION_ANSWER: `${PATH_PREMIUM_CADENCE}/v1/survey/answer`,
    QUEUES: `${PATH_TERMINAL_QUEUE}/v1/queues`,
    QUEUE_PARAMS: `${PATH_TERMINAL_QUEUE}/v1/params`,
    QUEUE_PRODUCTS: `${PATH_TERMINAL_QUEUE}/v1/products`,
    QUEUE_SHIPPING_COMPANY: `${PATH_TERMINAL_QUEUE}/v1/client-productivity`,
    QUIZ_LIST: `${PATH_INTEGRA_RODO}/v1/quiz`,
    QUOTAS: `${PATH_TRATO_RESERVATION}/v1/quotas`,
    QUOTAS_BRANCHES: `${PATH_TRATO_RESERVATION}/v1/quota-branches`,
    QUOTAS_CARRIERS: `${PATH_TRATO_RESERVATION}/v1/quota-carriers`,
    QUOTA_PANEL: `${PATH_TRATO_RESERVATION}/v1/quota-slot-management-panel`,
    QUOTA_PANEL_SCHEDULES: `${PATH_INTEGRA_RODO}/v1/schedules/quota-slot-management-panel/schedule-detail`,
    REGISTRATIONS_IMPORT: `${PATH_INTEGRA_RODO}/v1/groups/upload`,
    REPORT_ADHERENCE: `${PATH_TRATO_RESERVATION}/v1/quotas/report-adherence/general`,
    REPORT_CARGO_FLEX: `${PATH_CARGO_FLEX}/v1/occurences/report`,
    REPORT_JOURNEY: `${PATH_REPORTS}/v1/journey/xlsx`,
    RESCHEDULE: `${PATH}/supervisor/v1/event/schedules/on-management`,
    RESTRICTIONS: `${PATH_INTEGRA_RODO}/v1/restrictions`,
    REVIEW: `${PATH_INTEGRA_RODO}/v1/signed/user/review`,
    REVIEW_OPTIONS: `${PATH_INTEGRA_RODO}/v1/signed/user/review/options`,
    ROLES_BY_BUSINESS_GROUP: `${PATH}/supervisor/v1/signed/user/users/business-group`,
    ROLE_PROFILES: `${PATH}/supervisor/v1/roles/profiles`,
    ROOT_TRADERS_QUOTAS: `${PATH_INTEGRA_RODO}/v2/traders/find-root-traders`,
    ROOT_TRADER_BRANCHES: `${PATH_INTEGRA_RODO}/v2/root-traders-quotas`,
    SCHEDULE: `${PATH_INTEGRA_RODO}/v1/schedules/external-id`,
    SCHEDULES: `${PATH_INTEGRA_RODO}/v1/schedules`,
    SCHEDULES_CANCEL_REASONS: `${PATH_INTEGRA_RODO}/v1/schedules/schedule-cancel/descriptions-by-group`,
    SCHEDULES_CLOSURES: `${PATH_INTEGRA_RODO}/v1/schedules/closure`,
    SCHEDULES_EVENT: `${PATH_SUPERVISOR}/v1/event/schedules`,
    SCHEDULES_IMPORT_DOCUMENT: `${PATH_TRATO_RESERVATION}/v1/import-documents`,
    SCHEDULES_JOURNEY_STARTS: `${PATH_INTEGRA_RODO}/v1/schedules/journey-starts`,
    SCHEDULES_ORDERS_LIST: `${PATH_TRATO_RESERVATION}/v1/order-import-documents/document`,
    SCHEDULES_REMAINING: `${PATH_INTEGRA_RODO}/v1/signed/user/schedules/remaining`,
    SCHEDULES_SIGNED: `${PATH_INTEGRA_RODO}/v1/signed/user/schedules`,
    SCHEDULES_TRUCK_CLASS: `${PATH_INTEGRA_RODO}/v1/trucks/truck-modal-class`,
    SCHEDULE_FINISHED_RECEIPT: `${PATH_INTEGRA_RODO}/v1/schedules/receipt/finished-schedule`,
    SCHEDULE_MANAGEMENT: `${PATH_INTEGRA_RODO}/v1/schedules/management`,
    SCHEDULE_RECEIPT: `${PATH_INTEGRA_RODO}/v1/schedules/receipt/external-id`,
    SCHEDULE_SIOP: `${PATH_SIOP_INTEGRATION}/v1/schedule/bridge/resend`,
    SCHEDULE_TRAVEL_ENTRACE: `${PATH_SIOP_INTEGRATION}/v1/schedule/travel/entrance`,
    SCHEDULING_AGREEMENT: `${PATH_INTEGRA_RODO}/v1/schedules/scheduling-agreement`,
    SCHEDULING_AVAILABLE: `${PATH_TRATO_RESERVATION}/v1/reservations/available-from-now-on`,
    SEAPORTS: `${PATH_INTEGRA_RODO}/v2/seaports`,
    SEAPORTS_V1: `${PATH_INTEGRA_RODO}/v1/seaports/from-user-terminals`,
    SEND_EMAIL_RESET: `${PATH}/supervisor/v1/signed/user/users/email`,
    SHIPMENTS: `${PATH_DEMAND_PLAN}/v1/shipments`,
    SHIPMENTS_AVAILABLE_DATES: `${PATH_DEMAND_PLAN}/v1/shipping-order/available-dates`,
    SHIPPING_COMPANY: `${PATH_INTEGRA_RODO}/v1/shippingcompany`,
    SHIPPING_COMPANY_IMPORT_DOCUMENT: `${PATH_TRATO_RESERVATION}/v1/order-import-documents/document-and-order-number`,
    SHIPPING_COMPANY_V2: `${PATH_INTEGRA_RODO}/v2/shippingcompany`,
    SHIPPING_ORDER: `${PATH_DEMAND_PLAN}/v1/shipping-order`,
    SIGNED_AUTO_TERMINAL_CALLS: `${PATH_TERMINAL_QUEUE}/v2/signed/user/auto-terminal-calls`,
    SIGNED_LOAD_SCHEDULE: `${PATH_INTEGRA_RODO}/v1/signed/user/load-schedules`,
    SIGNED_QUEUE: `${PATH_TERMINAL_QUEUE}/v1/signed/user/queue`,
    SIGNED_QUEUE_V2: `${PATH_TERMINAL_QUEUE}/v2/signed/user/queue`,
    SIGNED_SHIPPING_COMPANY: `${PATH_INTEGRA_RODO}/v1/signed/user/shippingcompany`,
    SIGNED_SHIPPING_COMPANY_RESTRICTED: `${PATH_INTEGRA_RODO}/v1/signed/user/shippingcompany-restricted`,
    SIGNED_TERMINALS: `${PATH_INTEGRA_RODO}/v1/signed/user/terminals`,
    SIGNED_TERMINAL_CALLS: `${PATH_TERMINAL_QUEUE}/v1/signed/user/terminal-calls`,
    SIGNED_TERMINAL_CALLS_V2: `${PATH_TERMINAL_QUEUE}/v2/signed/user/terminal-calls`,
    SIGNED_TRADERS: `${PATH_INTEGRA_RODO}/v1/signed/user/traders`,
    SIGNED_TRADERS_RESTRICTED: `${PATH_INTEGRA_RODO}/v1/signed/user/traders-restricted`,
    SIGNED_TRAVELS: `${PATH_TERMINAL_QUEUE}/v1/signed/user/travels`,
    SIGNED_USERS: `${PATH}/supervisor/v1/signed/user/users/user-logins`,
    SLOTS: `${PATH_INTEGRA_RODO}/v1/slots`,
    SLOTS_CONSULT_PATTERN: `${PATH_INTEGRA_RODO}/v2/slots/default`,
    SLOTS_CONSULT_V2: `${PATH_INTEGRA_RODO}/v2/slots/exists`,
    SLOTS_EDIT_HEARDER: `${PATH_INTEGRA_RODO}/v2/slots/slot-totals`,
    SLOTS_V2: `${PATH_INTEGRA_RODO}/v2/slots`,
    SLOTS_V2_JSON: `${PATH_INTEGRA_RODO}/v2/slots/uploadJson`,
    SLOT_DISTRIBUTION_AGREEMENT: `${PATH_TRATO_RESERVATION}/v1/quota-slot-management-panel/slot-to-distribute`,
    SLOT_DISTRIBUTION_ORIGIN: `${PATH_TRATO_RESERVATION}/v1/quota-slot-management-panel/distribute/slot`,
    STOPPING_POINTS: `${PATH_STOPPING_POINT}/v1`,
    SUGGESTION: `${PATH_INTEGRA_RODO}/v1/signed/user/suggestions`,
    TERMINALS: `${PATH_INTEGRA_RODO}/v1/terminals`,
    TERMINALS_CLIENT_PRODUCTIVITY_BY_USER: `${PATH_TERMINAL_QUEUE}/v1/client-productivity/by-user`,
    TERMINALS_TICKETS: `${PATH_CARGO_FLEX}/v1/tickets/terminals`,
    TERMINALS_V2: `${PATH_INTEGRA_RODO}/v2/terminals`,
    TERMINALS_VLI: `${PATH_TERMINAL_QUEUE}/v1/terminal-queue/terminals/vli`,
    TERMINAL_CATEGORY: `${PATH_TERMINAL_QUEUE}/v1/contract-terminal-category`,
    TERMINAL_PER_USER: `${PATH_TERMINAL_QUEUE}/v1/signed/user/terminals`,
    TERMINAL_TRAVELS: `${PATH_TERMINAL_QUEUE}/v1/travels`,
    TERMINAL_WITH_CONTRACT: `${PATH_TERMINAL_QUEUE}/v1/client-terminal/terminal-with-contract`,
    TOTAL_CUSTUMER_CONTRACTS: `${PATH_TERMINAL_QUEUE}/v1/client-terminal/contracts`,
    TOTAL_TERMINAL_CONTRACTS: `${PATH_TERMINAL_QUEUE}/v1/client-terminal/terminals`,
    TRADERS: `${PATH_INTEGRA_RODO}/v1/traders`,
    TRADERS_ACCOUNT_HOLDER: `/account-holders`,
    TRADERS_FINAL_BUYERS: `/final-buyers`,
    TRADERS_FLOW: `${PATH_INTEGRA_RODO}/v2/traders-flow`,
    TRADERS_PARENT: `${PATH_INTEGRA_RODO}/v1/traders/parent`,
    TRADERS_SCHEDULE: `${PATH_INTEGRA_RODO}/v1/signed/user/schedule/traders`,
    TRADERS_TICKETS: `${PATH_CARGO_FLEX}/v1/tickets/traders`,
    TRADERS_USERS_PARENT: `${PATH_TRATO_RESERVATION}/v1/traders/user/root-traders`,
    TRADERS_V2: `${PATH_INTEGRA_RODO}/v2/traders`,
    TRAVEL_END_MANUALLY: `${PATH_SIOP_INTEGRATION}/v1/schedule/travel/closure`,
    TRAVEL_REPORT_JOURNEY: `${PATH_INTEGRA_RODO}/v3/travels/report/journey`,
    TRUCK: `${PATH_INTEGRA_RODO}/v2/truck`,
    TRUCKS: `${PATH_INTEGRA_RODO}/v1/trucks`,
    TRUCKS_UPDATE: `${PATH_INTEGRA_RODO}/v1/trucks/update-truck`,
    TRUCKS_V3: `${PATH_INTEGRA_RODO}/v3/trucks`,
    TRUCK_DRIVER: `${PATH_INTEGRA_RODO}/v1/truck-driver`,
    TRUCK_DRIVERS: `${PATH_INTEGRA_RODO}/v1/truck-drivers`,
    TRUCK_DRIVERS_CPF: `${PATH_INTEGRA_RODO}/v1/truck-drivers/cpf`,
    TRUCK_DRIVERS_SIGNED: `${PATH_INTEGRA_RODO}/v1/signed/user/truck-drivers`,
    TRUCK_DRIVER_AUTONOMOUS: `${PATH_TRUCK_DRIVER}/autonomous`,
    TRUCK_DRIVER_CHAPA: `${PATH_TRUCK_DRIVER}`,
    TRUCK_DRIVER_CHAPA_CONNECTIONS: `${PATH}/truck-driver/v1/chapas/connections`,
    TRUCK_DRIVER_CHAPA_CONNECTIONS_NOTIFICATIONS: `${PATH}/truck-driver/v1/chapas/notifications`,
    TRUCK_DRIVER_CHAPA_CONVERT_ID: `${PATH_TRUCK_DRIVER}/pre-registration-basic/convert/id`,
    TRUCK_DRIVER_CHAPA_EXPORT_PROFILE: `${PATH_TRUCK_DRIVER}/pre-registration-truck-driver/export`,
    TRUCK_DRIVER_CHAPA_REQUEST_GR: `${PATH_FREIGHT_MATCH}/v1/truck-driver/send-to-gr`,
    TRUCK_DRIVER_COMPANY: `${PATH_TRUCK_DRIVER}/company`,
    TRUCK_DRIVER_CONVERSION: `${PATH_TRUCK_DRIVER}/conversion`,
    TRUCK_DRIVER_FREIGHT: `${PATH_TRUCK_DRIVER}/freight`,
    TRUCK_DRIVER_PAYMENT: `${PATH_TRUCK_DRIVER}/freight-payment`,
    TRUCK_DRIVER_SEARCH_CPF: `${PATH_TRUCK_DRIVER}/truck-driver/searchByCpf`,
    TRUCK_DRIVER_SEARCH_LIST: `${PATH}/truck-driver/v2/company`,
    TRUCK_DRIVER_TERMINAL: `${PATH_TRUCK_DRIVER}/pre-registration/terminal`,
    TRUCK_DRIVER_TRUCK_COMPANY: `${PATH_TRUCK_DRIVER}/truck-company`,
    TRUCK_DRIVER_UPDATE: `${PATH_INTEGRA_RODO}/v1/truck-drivers/update`,
    TRUCK_DRIVER_V1: `${PATH_TRUCK_DRIVER}`,
    TRUCK_DRIVE_RESET: `${PATH_INTEGRA_RODO}/v1/users/reset-password`,
    TRUCK_PLATE_CHAPA: `${PATH}/integra-rodo-entidades/v3`,
    TRUCK_PLATE_SAVE_CHAPA: `${PATH}/supervisor/v3`,
    USERS: `${PATH_INTEGRA_RODO}/v1/signed/user/users`,
    PREMIUM_CADENCE_PROGRAMMING_PAUSE: `${PATH_PREMIUM_CADENCE}/v1/terminal/programming`,
  };
}
